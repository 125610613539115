import React, { useState, useEffect } from 'react'
import AppShell from '../../components/AppShell/AppShell'
import CONS from '../../common/Constants'
import Cruce from './Cruce'
import { useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import loader from '../../assets/img/loading.gif'
import notfound from '../../assets/img/404-2.gif'
import { Link } from 'react-router-dom'


export default function SeleccionarCruce() {

    const [state, setState] = useState(JSON.parse(localStorage.getItem('estado')))
    const [show, setShow] = useState(false)
    const history = useHistory()

    useEffect(() => {

        //! Recorrer resultado si existe mas de un destino con el mismo origen 
        
        if (state && !state.busqueda.resultado && state.busqueda) {

            let tramo_natales = state.busqueda[0].origen == 41 || state.busqueda[0].origen == 46 || state.busqueda[0].origen == 51 || state.busqueda[0].origen == 56 ? true : false;
            console.log(`Tramo natales:${tramo_natales}`);
        
            const url = CONS.api + '/api/cruces/tramos'

            let postData =  null;
            let cruces = [];
        
            // Recorrer todas las fechas en el array
            for (let i = 0; i < state.busqueda.length; i++) {
        
                // no hay resultados, hago la busqueda...
                const split = state.busqueda[i].fecha.split('-')
        
                const fecha = split[2] + '-' + split[1] + '-' + split[0]
        
                postData = {
                    fecha: fecha,
                    origen: parseInt(state.busqueda[i].origen),
                    destino: parseInt(state.busqueda[i].destino)
                };
        
                const headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                }
        
                fetch(url, headers)
                .then(res => res.json())
                .then(data => {
                    if(tramo_natales){
                        //Tramo natales sur o norte
                        let cruce_actual = data[0] ? data[0] : null;
                        if(cruce_actual != null){
                            cruces.push(data[0]);
                        }
                        
                        if(i == (state.busqueda.length - 1)){
                            console.log(`ultima iteración`);
                            let newState = { ...state }
                            newState.busqueda.resultado = cruces;
                            setState(newState)
                        }
                        
                        // cruces.forEach(cruce => {
                        //     crucesSeleccionados.push({
                        //         id_cruce: cruce.id_cruce,
                        //         tramo: cruce.nombre_tramo,
                        //         cupoPasajero: cruce.cupo_pasajeros_maximo,
                        //         cupoVehiculo: cruce.cupo_vehiculos_maximo,
                        //         horaPresentacion: cruce.horario_presentacion,
                        //         horaCruce: cruce.horario_cruce,
                        //         nombreNave: cruce.nombre_nave,
                        //         id_tramo: cruce.id_tramo
                        //     });
                        // });

                        setState({ ...state, cruceSeleccionado: state.busqueda.resultado })
                        
                        
                        // console.log(`cruces seleccionados:${JSON.stringify(crucesSeleccionados)}`);
                        // const crucesSeleccionados = []
                        
                        // crucesSeleccionados.push({
                        //     id_cruce: props.idCruce,
                        //     tramo: props.tramo,
                        //     cupoPasajero: props.cupoPasajero,
                        //     cupoVehiculo: props.cupoVehiculo,
                        //     horaPresentacion: props.horarioPresentacion,
                        //     horaCruce: props.horarioCruce,
                        //     nombreNave: props.nombreNave,
                        //     id_tramo: props.idTramo
                        // });
                        localStorage.setItem('estado', JSON.stringify({ ...state, cruceSeleccionado: state.busqueda.resultado }))
                        // return history.push('/escaner');
                    }else{
                        setState({ ...state, busqueda: { ...state.busqueda, resultado: data } })
                    }
                })
                .then(() => {
                    // console.log(`cruces antes de pasar a /escaner:${JSON.stringify(cruces)}`);
                    if(cruces.length > 0 && tramo_natales){
                        return history.push('/escaner');
                    }
                    setShow(true)
                })
            }
        }
    }, [state, setState])


    return (
        <AppShell titulo="Seleccione el cruce">
            { state.busqueda.resultado &&
                state.busqueda.resultado.length === 0 && 
                <div className="w-11/12 mx-auto pt-6 text-center">
                    <h1 className="text-7xl font-black italic text-blue-900">404</h1>
                    <small className="block w-full text-center text-yellow-600 text-sm px-4 my-2">No se encontraron resultados para su busqueda</small>
                    <div className="w-full text-center">
                        <img src={notfound} alt="404" className="my-4 mx-auto" />
                    </div>
                    <Link to="/configurar-tramo">
                        <button className="w-full block bg-yellow-600 text-white shadow-xl rounded uppercase font-semibold py-3 border-t border-l border-yellow-700">Cambiar fecha o tramo</button>
                    </Link>
                </div>
            }

            <div className="w-full mx-auto relative">
                <Transition
                    show={!show}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="w-full h-full bg-gray-white absolute z-20">
                        <img src={loader} alt="cargando..." className='px-12 mb-2 pt-10 mx-auto' />
                        <div className="text-center w-full text-yellow-600 uppercase font-black text-sm">Cargando resultados...</div>
                    </div>

                </Transition>

                <Transition
                    show={show}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="transition bg-gray-700 shadow overflow-hidden relative z-10">
                        <ul className="divide-y divide-gray-100">
                            {state.busqueda.resultado &&
                                state.busqueda.resultado.length > 0 &&
                                Object.keys(state.busqueda.resultado).map(item => {
                                    return (
                                        <li key={state.busqueda.resultado[item].id_cruce}>
                                            <Cruce
                                                idCruce={state.busqueda.resultado[item].id_cruce}
                                                idTramo={state.busqueda.resultado[item].id_tramo}
                                                tramo={state.busqueda.resultado[item].nombre_tramo}
                                                cupoPasajero={state.busqueda.resultado[item].cupo_pasajeros_maximo}
                                                cupoVehiculo={state.busqueda.resultado[item].cupo_vehiculos_maximo}
                                                horarioPresentacion={state.busqueda.resultado[item].horario_presentacion}
                                                horarioCruce={state.busqueda.resultado[item].horario_cruce}
                                                nombreNave={state.busqueda.resultado[item].nombre_nave}
                                            />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Transition>
            </div>
        </AppShell >
    )
}
