import React from 'react'
import ButtonComponent from './ButtonComponent'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Redirect } from 'react-router-dom';

export default function FooterComponent() {

    const salir = () => {
        confirmAlert({
            title: 'Salir del sistema',
            message: 'Al salir de la sesión se perderan todos los datos de conteo almacenados en el teléfono. ¿Está seguro que desea salir?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => { window.location = '/'}
                },
                {
                    label: 'No',
                    onClick: () => console.log('salir')
                }
            ]
        });
    }
    return (
        <div className="w-full flex items-center bg-gray-900 text-blue-400" >

            <ButtonComponent title="Tramo" link="/configurar-tramo" >
                <svg className="w-4 h-4 mb-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                </svg>
            </ButtonComponent>


            <ButtonComponent title="Escanear" link="/escaner">
                <svg className="w-4 h-4 mb-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                </svg>
            </ButtonComponent>


            {/* <ButtonComponent title="Acerca de" link="/acerca-de">
                <svg className="w-4 h-4 mb-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </ButtonComponent> */}

            <div class="w-full" onClick={salir}>
                <div className='w-full flex justify-center flex-wrap pb-2 pt-3' >
                    <svg className="w-4 h-4 mb-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <div className="font-light text-xs text-center w-full block px-2" >
                        Salir
                    </div>
                </div>
            </div>


        </div>
    )
}
